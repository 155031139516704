import React from 'react';

import Loader from '../../images/icon-loader.svg';

import './backdrop.css';

export default function LightboxBackdrop({ children, noLoader }) {
  return (
    <div
      className="backdrop hidden"
      style={{
        ...backdropStyle,
        backgroundImage: !noLoader && `url(${Loader})`,
      }}
    >
      {children}
    </div>
  );
}

const backdropStyle = {
  position: `fixed`,
  background: `rgba(0, 0, 0, 0.95)`,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 500,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
  overflowX: `auto`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center`,
  width: `100%`,
  height: `100%`,
};
